/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/no-named-as-default */
import { useUser } from '@folklore/auth';
import { useResizeObserver } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import { animated as a, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import classNames from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'wouter';

import { useMicromag } from '../../hooks/usePage';
import useScrollToTop from '../../hooks/useScrollToTop';
import { useTrackEvent, useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { checkClickable } from '../../lib/utils';

import { AdsTargetingProvider } from '../../contexts/AdsTargetingContext';
import { useSetPage, useSetStatusCode } from '../../contexts/NavigationContext';
import { useSite } from '../../contexts/SiteContext';
import ArticleMeta from '../meta/ArticleMeta';
import MicromagFirstScreenButton from '../micromag/MicromagFirstScreenButton';
import MicromagGrid from '../micromag/MicromagGrid';
import SubscribeModal from '../micromag/SubscribeModal';
import Micromag from '../partials/Micromag';

import styles from '../../../styles/pages/micromag.module.scss';

const propTypes = {
    micromag: AppPropTypes.article,
    micromagSlug: PropTypes.string.isRequired,
    micromagPath: PropTypes.string,
    isDisabled: PropTypes.bool,
    subscribeMaxScreenIndex: PropTypes.number,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    micromag: null,
    micromagPath: null,
    isDisabled: false,
    subscribeMaxScreenIndex: 4,
    onMetadataChange: null,
};

function MicromagPage({
    micromag,
    micromagSlug,
    micromagPath,
    isDisabled,
    subscribeMaxScreenIndex,
    onMetadataChange,
}) {
    // const { micromag_path: micromagPath = null } = useParams();
    const [location, setLocation] = useLocation();
    const site = useSite();
    const { id: siteId = null } = site || {};
    const setStatusCode = useSetStatusCode();
    const setMicromag = useSetPage();
    const generateUrl = useUrlGenerator();
    const user = useUser();
    const basePath = generateUrl('micromags.show', {
        micromag: micromagSlug,
    });

    const trackEvent = useTrackEvent();

    // Scroll to top when this is the first article rendering
    // const firstArticle = useMemo(() => article, [article !== null]);
    const firstMicromagRef = useRef(micromag);
    if (firstMicromagRef.current === null && micromag !== null) {
        firstMicromagRef.current = micromag;
    }
    useScrollToTop(firstMicromagRef.current);

    // Load article
    const onLoad = useCallback(
        (newMicromag) => {
            if (newMicromag !== null) {
                setMicromag(newMicromag);
            }
            return newMicromag;
        },
        [setMicromag],
    );
    const onError = useCallback(() => setStatusCode(404), [setStatusCode]);
    useMicromag({
        micromag,
        slug: micromagSlug,
        onLoad,
        onError,
    });

    const {
        id = null,
        title = null,
        micromag: micromagData = null,
        url: micromagUrl = null,
        slug: currentSlug = null,
        subscribeModal = null,
    } = micromag || {};

    // console.log('micromagData', micromag);

    const finalMicromagData = useMemo(() => {
        if (micromagData === null || currentSlug !== micromagSlug) {
            return null;
        }
        const { components, ...data } = micromagData;
        return {
            components: [
                ...components,
                siteId !== 'micromag-france'
                    ? {
                          id: 'micromags',
                          type: 'micromags',
                      }
                    : null,
            ].filter((it) => it !== null),
            ...data,
        };
    }, [siteId, micromagData, currentSlug, micromagSlug]);

    const { components: screens = [] } = finalMicromagData || {};

    // Modal features
    const {
        title: subscribeTitle = null,
        subtitle: subscribeSubtitle = null,
        component: subscribeComponent = null,
        screenIndex: subscribeScreenIndex = null,
        sticker: subscribeSticker = null,
        stickerCustom: subscribeStickerCustom = null,
        showOnLoad: subscribeModalShowOnLoad = false,
        canDismiss: subscribeModalCanDismiss = true,
    } = subscribeModal || {};

    const partialSubscribeScreenIndex =
        subscribeScreenIndex !== null ? subscribeScreenIndex - 1 : null;
    const finalSubscribeMaxScreenIndex = partialSubscribeScreenIndex || subscribeMaxScreenIndex;

    const [screenChanged, setScreenChanged] = useState(false);

    const [pathScreenId = null] = (micromagPath || '').replace(/^\//, '').split('/');
    const pathScreenIndex =
        pathScreenId !== null && pathScreenId.match(/^[0-9]+$/) !== null
            ? parseInt(pathScreenId, 10) - 1
            : null;
    const screenByPathId =
        pathScreenId !== null
            ? screens.find(({ id: screenId }) => screenId === pathScreenId) || null
            : null;
    const screenByPathIndex = pathScreenIndex !== null ? screens[pathScreenIndex] || null : null;
    const currentScreen =
        screenByPathId ||
        screenByPathIndex ||
        (pathScreenId === '' ? screens[0] : null) ||
        screens[0] ||
        null;

    const screenIndex = useMemo(
        () =>
            currentScreen !== null
                ? screens.findIndex(({ id: screenId }) => screenId === currentScreen.id)
                : -1,
        [currentScreen, screens],
    );
    const [showFirstScreenButton, setShowFirstScreenButton] = useState(screenIndex > 0);

    const onScreenChange = useCallback(
        (newScreen) => {
            const newScreenIndex = screens.findIndex(
                ({ id: screenId }) => screenId === newScreen.id,
            );
            setLocation(newScreenIndex > 0 ? `${micromagUrl}/${newScreenIndex + 1}` : micromagUrl);
            setShowFirstScreenButton(false);
            setScreenChanged(true);
        },
        [screens, micromagUrl, setShowFirstScreenButton, setScreenChanged, setLocation],
    );

    const onMenuChange = useCallback(
        (menuState) => {
            const { menuOpen = false, shareOpen = false } = menuState || {};
            if (menuOpen || shareOpen) {
                setShowFirstScreenButton(false);
            }
        },
        [setShowFirstScreenButton],
    );

    const onInteraction = useCallback(() => {
        const { type = null } = currentScreen || {};
        if (type === 'urbania-horoscope') {
            setScreenChanged(true);
        }
    }, [currentScreen]);

    // Pires moments de l'histoire
    const screenIsAlwaysBlocked = useMemo(
        () => screenIndex === 26 && id === '439352',
        [screenIndex, id],
    );
    // console.log(screenIsAlwaysBlocked, user, screenIndex, id);

    const shouldSubscribe = useMemo(
        () =>
            (siteId === 'micromag' || siteId === 'micromag-france') &&
            user === null &&
            screenIndex >= finalSubscribeMaxScreenIndex &&
            (subscribeModalShowOnLoad || screenChanged || screenIsAlwaysBlocked),
        [
            siteId,
            user,
            screenIndex,
            finalSubscribeMaxScreenIndex,
            subscribeModalShowOnLoad,
            screenIsAlwaysBlocked,
            screenChanged,
        ],
    );

    const [subscribeModalOpened, setSubscribeModalOpened] = useState(shouldSubscribe);
    // console.log('opened', subscribeModalOpened);

    const onDismissSubscribeModal = useCallback(() => {
        setSubscribeModalOpened(false);
        trackEvent('Subscription', 'dismiss_modal', 'micromag');
    }, [setSubscribeModalOpened, trackEvent]);

    const onSubscribeModalComplete = useCallback(() => {
        setSubscribeModalOpened(false);
        trackEvent('Subscription', 'complete_modal', 'micromag');
    }, [setSubscribeModalOpened, trackEvent]);

    useEffect(() => {
        if (shouldSubscribe) {
            setSubscribeModalOpened(true);
            trackEvent('Subscription', 'show_modal', 'micromag');
        }
    }, [shouldSubscribe, trackEvent, setSubscribeModalOpened]);

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current === null) {
            return () => {};
        }
        const currentRef = containerRef.current;
        // disableBodyScroll(containerRef.current);
        function onTouch(e) {
            const pageX = e.touches ? e.touches[0].pageX : e.pageX;
            if ((pageX > 20 && pageX < window.innerWidth - 20) || checkClickable(e.target)) {
                return;
            }
            e.preventDefault();
        }
        currentRef.addEventListener('touchstart', onTouch);
        return () => {
            // enableBodyScroll(containerRef.current);
            currentRef.removeEventListener('touchstart', onTouch);
        };
    }, [micromag, containerRef.current]);

    const [visualViewport, setVisualViewport] = useState(null);
    useEffect(() => {
        if (typeof window.visualViewport === 'undefined') {
            return () => {};
        }
        function updateViewport() {
            const { offsetTop: visualTop = 0, height: visualHeight = '100%' } =
                window.visualViewport;
            setVisualViewport({
                top: visualTop,
                height: visualHeight,
            });
        }
        window.visualViewport.addEventListener('scroll', updateViewport);
        window.visualViewport.addEventListener('resize', updateViewport);
        return () => {
            window.visualViewport.removeEventListener('scroll', updateViewport);
            window.visualViewport.removeEventListener('resize', updateViewport);
        };
    }, [setVisualViewport]);

    /**
     * First screen button
     */
    const {
        ref: buttonRef,
        entry: { contentRect: buttonRect = null },
    } = useResizeObserver();
    const { width: buttonWidth = 0 } = buttonRect || {};
    const [buttonSpring, setButtonSpring] = useSpring(() => ({
        x: -110,
        opacity: 0,
    }));

    const onButtonDrag = useCallback(
        ({ active, movement: [mx] }) => {
            if (mx < 0) {
                setButtonSpring.start({
                    x: active ? mx : 0,
                });
            }
        },
        [setButtonSpring],
    );

    const onButtonDragEnd = useCallback(
        ({ movement: [mx] }) => {
            if (Math.abs(mx) > 100 || Math.abs(mx) > buttonWidth / 2) {
                setShowFirstScreenButton(false);
            }
        },
        [setShowFirstScreenButton, buttonWidth],
    );

    const bind = useGesture(
        {
            onDrag: onButtonDrag,
            onDragEnd: onButtonDragEnd,
        },
        { drag: { axis: 'x', filterTaps: true } },
    );

    useEffect(() => {
        if (showFirstScreenButton) {
            setButtonSpring({
                x: 0,
                opacity: 1,
            });
        } else {
            setButtonSpring({
                x: -110,
                opacity: 0,
            });
        }
    }, [showFirstScreenButton]);

    const onClickFirstScreenButton = useCallback(() => {
        setShowFirstScreenButton(false);
        trackEvent('Navigation', 'first_screen_button', screenIndex);
    }, [setShowFirstScreenButton, trackEvent, screenIndex]);

    const onClickMicromag = useTrackOnClickLink('click_menu_micromag_suggestion');

    if (micromag === null) {
        return null;
    }

    const titleParts = isString(title) ? title.split('–') : null;
    const [mainTitle = null, badgeTitle = null] = titleParts || [];

    const viewerMenuHeader = (
        <div className={styles.micromagHeader}>
            {badgeTitle !== null ? <div className={styles.badge}>{badgeTitle}</div> : null}
            {mainTitle !== null ? <h1 className={styles.title}>{mainTitle}</h1> : null}
        </div>
    );

    const viewerMenuFooter =
        siteId !== 'micromag-france' ? (
            <div className={styles.micromagFooter}>
                <MicromagGrid
                    query={{
                        exclude: id,
                    }}
                    external={false}
                    title={<FormattedMessage defaultMessage="Plus de micromags" />}
                    className={styles.list}
                    titleClassName={styles.title}
                    cardsClassName={styles.cards}
                    itemClassName={styles.item}
                    onClickItem={onClickMicromag}
                    withDate
                />
            </div>
        ) : null;

    return (
        <main
            className={classNames([
                styles.container,
                {
                    [styles.subscribeModalOpened]: subscribeModalOpened,
                },
            ])}
            ref={containerRef}
            style={visualViewport}
        >
            {!isDisabled ? <ArticleMeta article={micromag} onChange={onMetadataChange} /> : null}
            <div key={`article-${id}`} id={`article-${id}`}>
                <AdsTargetingProvider page={micromag}>
                    <Micromag
                        micromag={finalMicromagData}
                        currentScreenId={currentScreen !== null ? currentScreen.id : null}
                        paused={subscribeModalOpened}
                        className={styles.micromag}
                        basePath={basePath}
                        withoutClickToStart
                        withoutRouter
                        onScreenChange={onScreenChange}
                        onMenuChange={onMenuChange}
                        viewerMenuHeader={viewerMenuHeader}
                        viewerMenuFooter={viewerMenuFooter}
                        onInteraction={onInteraction}
                    />
                    <a.div
                        ref={buttonRef}
                        className={styles.buttonContainer}
                        {...bind()}
                        style={buttonSpring}
                    >
                        <MicromagFirstScreenButton
                            href={micromagUrl}
                            onClick={onClickFirstScreenButton}
                            micromag={finalMicromagData}
                            isStatic
                            className={classNames([
                                styles.toStartButton,
                                {
                                    [styles.disabled]: !showFirstScreenButton,
                                },
                            ])}
                            coverClassName={styles.buttonCover}
                        />
                    </a.div>
                    <SubscribeModal
                        nextUrl={micromagUrl}
                        className={styles.modal}
                        onDismiss={onDismissSubscribeModal}
                        onComplete={onSubscribeModalComplete}
                        withDismiss={subscribeModalCanDismiss}
                        component={subscribeComponent}
                        title={subscribeTitle}
                        subtitle={subscribeSubtitle}
                        sticker={subscribeSticker}
                        stickerCustom={subscribeStickerCustom}
                    />
                </AdsTargetingProvider>
            </div>
        </main>
    );
}

MicromagPage.propTypes = propTypes;
MicromagPage.defaultProps = defaultProps;

export default MicromagPage;
